import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'goods_delivery_id', store: 'warehouse.goods_deliveries' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'outbound_pickers',
    paginate: false
  },
  {
    default_query: {
      include: "box",
    }
  },
  touchs
).store()

export default resource
