import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'freight_forwarders',
  },
  {
    default_query: {
      // append: "user",
    },
    detail_query: {}
  }
).store()

export default resource
