import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'box_containers',
  },
  {
    default_query: {
      include: 'box,container',
      sort: '-container_id'
    },
  },
).store()

export default resource
