import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_decouple_items',
    prefix: 'warehouse.boxes_decouple_items',
  },
  {
    default_query: {
      include: 'children,parent,tags,items,items.product',
      sort: "-updated_at",
    },
    detail_query: {
      include: 'children,parent,items,items.product',
    }
  },
).store()

export default resource
