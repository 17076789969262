import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'box_items',
  },
  {
    default_query: {
    },
  },
).store()

export default resource
