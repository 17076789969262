<template>
  <div
    class="c-app"
    :class="{ 'c-dark-theme': $store.state.sidebar.sidebar_dark_mode }"
  >
    <TheSidebar />
    <CWrapper>
      <TheHeader root_path="warehouse">
        <template #body>
          <SSelectWarehouseScope
            class="select-w-scope d-inline-flex text-nowrap"
          />
        </template>
        <template #header-nav-right>
          <TheHeaderPrinterStatus
            @click.native="
              $router
                .push({ path: `/warehouse/settings/general` })
                .catch(() => {})
            "
            style="cursor: pointer"
          />
        </template>
      </TheHeader>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid class="container-warehouse h-100">
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "@/containers/TheSidebar";
import TheHeader from "@/containers/TheHeader";
import TheFooter from "@/containers/TheFooter";
import navItems from "../mixins/nav";
import modules from "../store";
import Vue from "vue";
import mixin from "../mixins/component";
import authenMixin from "@/core/components/mixins/authentication";
import registerStoreMixin from "@/core/components/mixins/registerStore";
import TheHeaderPrinterStatus from "./TheHeaderPrinterStatus.vue";

Vue.mixin(mixin);

export default {
  name: "TheWarehouseContainer",
  mixins: [authenMixin, registerStoreMixin],
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheHeaderPrinterStatus,
  },
  storeModules: modules,
  created() {
    this.$store.dispatch("nav.set", navItems);
    this.$store.dispatch("auth.users.fetch.if-first-time");
    this.$store.dispatch("warehouse.delivery_partners.fetch.if-first-time");
    this.$store.dispatch("accounting.currencies.fetch.if-first-time");
    this.$store.dispatch("warehouse.areas.fetch.if-first-time");
    this.$store.dispatch("warehouse.agencies.fetch.if-first-time");
    this.$store.dispatch("warehouse.shipment_methods.fetch.if-first-time");
    this.$store.dispatch(
      "warehouse.goods_delivery_statuses.fetch.if-first-time"
    );
    this.$store.dispatch("warehouse.pallet_types.fetch.if-first-time");
    this.$store.dispatch("printer.first-connect");
    this.$store.dispatch("product.categories.fetch.if-first-time");
    this.$store.dispatch("product.origins.fetch.if-first-time");
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
// Import main styles for this container
.container-warehouse {
  @import "../assets/scss/style";
}
</style>
