import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_ref_decouple',
    prefix: 'warehouse.boxes_ref_decouple',
  },
).store()

export default resource
