import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'sfa_id', store: 'warehouse.sfas' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_available_in_lading_bill',
    prefix: 'warehouse.boxes_available_in_lading_bill',
  },
  {
    default_query: {
      include: "tags",
    }
  },
  touchs
).store()

export default resource
