import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'shelves',
  },
  {
    default_query: {
      append: 'locations',
      include: 'locations.pallet.pivotBoxes'
    }
  }
).store()

export default resource
