import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'container_id', store: 'warehouse.invoices' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'out_container_pickers',
    paginate: false
  },
  {
    default_query: {
      include: 'box',
      sort: "-created_at",
    }
  },
  touchs
).store()

export default resource
