import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'pallet_id', store: 'warehouse.pallets' }),
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'pallet_boxes',
  },
  {
    default_query: {
      include: "box",
    },
  },
  touchs
).store()

export default resource
