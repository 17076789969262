import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'sfa_receipts',
    prefix_state: 'warehouse_tracking_receipts',
    prefix: 'warehouse.tracking_receipts',
  },
  {
    default_query: {
      include: "sfa",
      sort: "-created_at"
    }
  }
).store()

export default resource
