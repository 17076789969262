export default [
  {
    _name: "TSidebarNavItem",
    name: "Dashboard",
    to: "/warehouse/dashboard",
    icon: "cil-speedometer",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Inbound",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tracking images",
    to: "/warehouse/inbound/tracking-images",
    icon: "cis-images",
  },
  {
    _name: "TSidebarNavItem",
    name: "Purchase order",
    to: "/warehouse/inbound/purchase-order",
    icon: "cid-inbox-in",
  },
  {
    _name: "TSidebarNavItem",
    name: "Inbound transfer order",
    to: "/warehouse/inbound/transfer-order",
    icon: "cid-inbox-in",
  },
  {
    _name: "TSidebarNavItem",
    name: "Sales return order",
    to: "/warehouse/inbound/sales-return-order",
    icon: "cid-inbox-in",
    disabled: true,
  },
  {
    _name: "TSidebarNavTitle",
    content: "Internal",
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Box management',
    icon: "cil-box-alt",
    route: "/warehouse/internal/boxes",
    items: [
      {
        name: "Box",
        to: "/warehouse/internal/boxes/management",
      },
      {
        name: "Decouple",
        to: "/warehouse/internal/boxes/decouple",
      },
      {
        name: "Decouple box item",
        to: "/warehouse/internal/boxes/decouple-box-item",
      },
      {
        name: "Combine",
        to: "/warehouse/internal/boxes/combine",
      },
    ]
  },
  {
    _name: "TSidebarNavItem",
    name: "Assemble to stock",
    to: "/warehouse/internal/assemble-to-stock",
    icon: "cil-storage",
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Distribution',
    icon: "cil-library-books",
    route: "/warehouse/internal/owning-boxes",
    items: [
      {
        name: "Assemble to order",
        to: "/warehouse/internal/owning-boxes/assemble-to-order",
      },
      {
        name: "Distributed goods",
        to: "/warehouse/internal/owning-boxes/distributed",
      },
      {
        name: "Goods not yet distributed",
        to: "/warehouse/internal/owning-boxes/not-distributed-yet",
      },
    ]
  },
  {
    _name: "TSidebarNavItem",
    name: "Lading bills",
    to: "/warehouse/internal/lading-bills",
    icon: "cil-queue",
  },
  {
    _name: "TSidebarNavItem",
    name: "Invoices",
    to: "/warehouse/internal/invoices",
    icon: "cil-list-high-priority",
  },
  {
    _name: "TSidebarNavItem",
    name: "Goods deliveries",
    to: "/warehouse/internal/goods-deliveries",
    icon: "cil-scale-image",
  },
  {
    _name: "TSidebarNavItem",
    name: "Container inspection",
    to: "/warehouse/internal/container-inspection",
    icon: "cil-widgets",
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Storage',
    icon: "cil-storage",
    route: "/warehouse/internal/storage",
    items: [
      {
        name: "Warehouse model",
        to: "/warehouse/internal/storage/model",
      },
      {
        name: "Goods statistics",
        to: "/warehouse/internal/storage/statistics",
      },
      {
        name: "Inventory",
        to: "/warehouse/internal/storage/inventory",
      },
      {
        name: "Inventory by customer",
        to: "/warehouse/internal/storage/inventory-by-customer",
      },
      {
        name: "Clean up warehouse",
        to: "/warehouse/internal/storage/clean-up",
      },
    ]
  },
  {
    _name: "TSidebarNavTitle",
    content: "Outbound",
  },
  {
    _name: "TSidebarNavItem",
    name: "Sales order",
    to: "/warehouse/outbound/sales-order",
    icon: "cid-inbox-out",
  },
  {
    _name: "TSidebarNavItem",
    name: "Outbound transfer order",
    to: "/warehouse/outbound/transfer-order",
    icon: "cid-inbox-out",
  },
  {
    _name: "TSidebarNavItem",
    name: "Purchase return order",
    to: "/warehouse/outbound/purchase-return-order",
    icon: "cid-inbox-out",
    disabled: true,
  },
  {
    _name: "TSidebarNavTitle",
    content: "Management",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tracking",
    to: "/warehouse/management/tracking",
    icon: "cil-cursor",
  },
  {
    _name: "TSidebarNavItem",
    name: "Agency",
    to: "/warehouse/management/agency",
    icon: "cil-store",
  },
  {
    _name: "TSidebarNavItem",
    name: "Freight forwarder",
    to: "/warehouse/management/freight-forwarder",
    icon: "cil-share-all",
  },
  {
    _name: "TSidebarNavItem",
    name: "Container type",
    to: "/warehouse/management/container-type",
    icon: "cil-puzzle",
  },
  {
    _name: "TSidebarNavItem",
    name: "Delivery partner",
    to: "/warehouse/management/delivery-partner",
    icon: "cil-truck",
  },
  {
    _name: "TSidebarNavItem",
    name: "Delivery address",
    to: "/warehouse/management/delivery-address",
    icon: "cil-location-pin",
  },
  {
    _name: "TSidebarNavItem",
    name: "Product",
    to: "/warehouse/management/products",
    icon: "cil-chart-table",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Setting",
  },
  {
    _name: "TSidebarNavItem",
    name: "General",
    to: "/warehouse/settings/general",
    icon: "cil-settings",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Support",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support",
    to: "/warehouse/supports",
    icon: "cil-comment-bubble-question",
  },
];
