import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'pallets',
    prefix_state: 'warehouse_pallets_list',
    prefix: 'warehouse.pallets_list',
  },
  {
    default_query: {
      include: "type,location",
      sort: "-created_at",
    },
  },
).store()

export default resource
