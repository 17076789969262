import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'sfa_id', store: 'warehouse.sfas' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
  },
  {
    default_query: {
      include: 'children,parent,tags,items.product',
      sort: "-updated_at",
    },
    detail_query: {
      include: 'children,parent,pallets,pivotPallets,sfa,tags,items.product'
    }
  },
  touchs
).store()

export default resource
