<template>
  <div class="d-flex align-items-center mx-3 d-none d-sm-block">
    <CIcon
      name="cil-print"
      :class="[isConnected ? 'text-success' : 'text-danger']"
    />
    <CBadge class="ml-1" :color="isConnected ? 'success' : 'danger'">{{
      isConnected ? $t("Connected") : $t("Not connected")
    }}</CBadge>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isConnected: "printer.isConnected",
    }),
  },
};
</script>
