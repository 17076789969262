import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'merge_package',
  },
).store()

const PREFIX = "warehouse.merge_package";

const actions = {
  [PREFIX + ".detail.merge"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.detail.set-updating', true);
      tomoni.warehouse.merge_package
        .merge(attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        })
        .finally(() => {
          context.commit(PREFIX + '.detail.set-updating', false);
        })
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
