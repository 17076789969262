import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_parent',
    prefix: 'warehouse.boxes_parent',
  },
  {
    default_query: {
      "filter[is_parent_box]": 1,
      sort: "-updated_at",
      include: 'children,tags',
    },
    detail_query: {
      include: 'children'
    }
  },
).store()

export default resource
