import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'lading_bills',
    prefix_state: 'warehouse_lading_bills_available_for_invoice',
    prefix: 'warehouse.lading_bills_available_for_invoice',
  },
  {
    default_query: {
      include: "customer",
      'filter[available_for_container]': 1,
      sort: "-created_at",
    },
    default_detail: {
      customer: {},
    }
  }
).store()

export default resource
