import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
    prefix: 'warehouse.assemble_orders',
    prefix_state: 'warehouse_assemble_orders',
  },
  {
    default_query: {
      append: "owning_boxes",
      include: "status,customer",
      sort: "-updated_at",
      'filter[active]': 1,
    },
    detail_query: {
      append: "owning_boxes",
      include: "status,customer",
    }
  }
).store()

export default resource
