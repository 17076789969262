import Resource, { Touch } from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const touchs = [
  new Touch({ key: 'goods_delivery_id', store: 'warehouse.goods_deliveries' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'goods_delivery_boxes',
  },
  {
    default_query: {
      include: 'box,customer',
    }
  },
  touchs
).store()


const PREFIX = "warehouse.goods_delivery_boxes";

const actions = {
  [PREFIX + ".create_multiple"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.warehouse.goods_delivery_boxes
        .createMultiple(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Created",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
