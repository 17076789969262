import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'sfa_id', store: 'warehouse.sfas' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_available_for_distribution',
    prefix: 'warehouse.boxes_available_for_distribution',
  },
  {
    default_query: {
      "filter[distribution]": 1,
      "filter[is_parent_box]": 0,
      include: "tags"
    }
  },
  touchs
).store()

export default resource
