import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'delivery_partners',
  },
  {
    default_query: {
      sort: "-place_of_delivery_id",
      include: "placeOfDelivery"
    },
    default_detail: {
      place_of_delivery: {
        name: null,
        consignee: null,
        tel: null,
        note: null,
        ward_id: null,
        address: null,
      }
    }
  },
).store()

export default resource
