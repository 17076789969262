import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'box_tags',
    paginate: false,
  },
  {
    default_query: {
    },
    detail_query: {}
  }
).store()

export default resource
