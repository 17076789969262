import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'statistics_quantity_in_inventory_user',
    paginate: false,
  },
  {
    default_query: {
    }
  }
).store()

export default resource
