import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_has_owners',
    prefix: 'warehouse.boxes_has_owners',
  },
  {
    default_query: {
      sort: "-updated_at",
      "filter[has_owners]": 1,
      // "filter[is_parent_box]": 0,
      include: 'tags,items',
    },
  },
).store()

export default resource
