import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'owning_boxes',
    prefix_state: 'warehouse_order_owning_boxes',
    prefix: 'warehouse.order_owning_boxes',
  },
).store()

export default resource
