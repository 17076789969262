import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_not_in_lading_bills',
    prefix: 'warehouse.boxes_not_in_lading_bills',
  },
  {
    default_query: {
      sort: "-updated_at",
      include: "tags,items",
      "filter[not_in_lading_bill_with_expected]": 1,
    },
    detail_query: {
      include: "sfa"
    }
  },
).store()

export default resource
