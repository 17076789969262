import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'sfa_id', store: 'warehouse.sfas' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_available_in_pallet',
    prefix: 'warehouse.boxes_available_in_pallet',
  },
  {
    default_query: {
      "filter[available_in_pallet]": 1,
      include: "tags",
    },
  },
  touchs
).store()

export default resource
