import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'statistics_quantity_products_inventory',
  },
  {
    default_query: {
      include: 'product',
    }
  }
).store()

export default resource
