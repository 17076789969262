import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_without_container',
    prefix: 'warehouse.boxes_without_container',
  },
  {
    default_query: {
      sort: "-updated_at",
      "filter[not_in_picker_compared_with_expected]": 0,
      include: 'tags,items',
    },
  },
).store()

export default resource
