import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const touches = []

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'containers',
    prefix: 'warehouse.invoices_in',
    prefix_state: 'warehouse_invoices_in',
  },
  {
    default_query: {
      include: "shipmentMethod,fromArea,toArea",
      append: "in_pickers,out_pickers",
      sort: "-created_at",
    },
    detail_query: {
      append: "boxes_in_lading_bills,in_pickers,out_pickers",
      include: "shipmentMethod,fromArea,toArea,inPickersCount,outPickersCount,ladingBills",
    },
    default_detail: {
      had_cut_off: false
    }
  }
).store()

const PREFIX = "warehouse.invoices_in";

const actions = {
  [PREFIX + ".detail.lock"](context) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.detail.set-updating', true);
      tomoni.warehouse.containers.lock(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.commit(PREFIX + '.detail.merge', data)
          touches.forEach(touch => {
            if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
              context.dispatch(touch.STORE + '.detail.refresh')
            }
          })
          context.commit("toasts.push", {
            message: "Locked",
            type: "success",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.unlock"](context) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.detail.set-updating', true);
      tomoni.warehouse.containers.unlock(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.commit(PREFIX + '.detail.merge', data)
          touches.forEach(touch => {
            if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
              context.dispatch(touch.STORE + '.detail.refresh')
            }
          })
          context.commit("toasts.push", {
            message: "Unlocked",
            type: "warning",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
