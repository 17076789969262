import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_management',
    prefix: 'warehouse.boxes_management',
  },
  {
    default_query: {
      include: 'children,parent,tags,items.product',
      sort: "-updated_at",
    },
    detail_query: {
      include: 'children,parent,pallets,owners,pivotPallets,sfa,tags,items.product'
    }
  },
).store()

const PREFIX = "warehouse.boxes_management";

const actions = {
  [PREFIX + ".detail.attach-tags"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.warehouse.boxes
        .attachTags(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".attach-tags"](context, { id, attributes }) {
    return new Promise((resolve, reject) => {
      tomoni.warehouse.boxes
        .attachTags(id, attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.detach-tags"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.warehouse.boxes
        .detachTags(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Deleted",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".open"](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.warehouse.boxes.open(id)
        .then(({ data }) => {
          context.commit(PREFIX + '.set-updating', false);
          context.commit("toasts.push", {
            message: "Open",
            type: "success",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
  [PREFIX + ".close"](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.warehouse.boxes.close(id)
        .then(({ data }) => {
          context.commit(PREFIX + '.set-updating', false);
          context.commit("toasts.push", {
            message: "Closed",
            type: "danger",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};

