import pallet_types from './warehouse/pallet_types'
import shelves from './warehouse/shelves'
import boxes from './warehouse/boxes'
import agencies from './warehouse/agencies'
import goods_delivery_statuses from './warehouse/goods_delivery_statuses'
import pallets from './warehouse/pallets'
import pallet_boxes from './warehouse/pallet_boxes'
import assemble_orders from './warehouse/assemble_orders'
import invoices from './warehouse/invoices'
import goods_delivery_boxes from './warehouse/goods_delivery_boxes'
import outbound_pickers from './warehouse/outbound_pickers'
import delivery_partners from './warehouse/delivery_partners'
import in_container_pickers from './warehouse/in_container_pickers'
import out_container_pickers from './warehouse/out_container_pickers'
import sfa_receipts from './warehouse/sfa_receipts'
import boxes_available_in_pallet from './warehouse/boxes_available_in_pallet'
import boxes_available_for_distribution from './warehouse/boxes_available_for_distribution'
import boxes_available_in_lading_bill from './warehouse/boxes_available_in_lading_bill'
import lading_bills_available_for_invoice from './warehouse/lading_bills_available_for_invoice'
import box_items from './warehouse/box_items'
import locations from './warehouse/locations'
import boxes_available_for_goods_delivery from './warehouse/boxes_available_for_goods_delivery'
import decouple_box from './warehouse/decouple_box'
import create_parent_box from './warehouse/create_parent_box'
import pallets_of_sfa from './warehouse/pallets_of_sfa'
import place_of_deliveries from './warehouse/place_of_deliveries'
import boxes_ref_decouple from './warehouse/boxes_ref_decouple'
import boxes_freedom from './warehouse/boxes_freedom'
import boxes_parent from './warehouse/boxes_parent'
import tracking_info from './warehouse/tracking_info'
import box_containers from './warehouse/box_containers'
import boxes_management from './warehouse/boxes_management'
import merge_package from './warehouse/merge_package'
import box_tags from './warehouse/box_tags'
import boxes_not_distributed_yet from './warehouse/boxes_not_distributed_yet'
import boxes_has_owners from './warehouse/boxes_has_owners'
import boxes_without_container from './warehouse/boxes_without_container'
import pallets_list from './warehouse/pallets_list'
import change_sku_position from './warehouse/change_sku_position'
import boxes_doesnt_have_owner from './warehouse/boxes_doesnt_have_owner'
import boxes_not_in_lading_bills from './warehouse/boxes_not_in_lading_bills'
import clear_empty_pallets from './warehouse/clear_empty_pallets'
import invoices_in from './warehouse/invoices_in'
import invoices_out from './warehouse/invoices_out'
import tracking_receipts from './warehouse/tracking_receipts'
import order_owning_boxes from './warehouse/order_owning_boxes'
import decouple_box_items from './warehouse/decouple_box_items'
import boxes_decouple_items from './warehouse/boxes_decouple_items'
import statistics_quantity_products_inventory from './warehouse/statistics_quantity_products_inventory'
import freight_forwarders from './warehouse/freight_forwarders'
import container_types from './warehouse/container_types'
import house_airway_bills from './warehouse/house_airway_bills'
import statistics_quantity_in_inventory_user from './warehouse/statistics_quantity_in_inventory_user'
import cancel_distributed from './warehouse/cancel_distributed'

export default {
  pallet_types,
  shelves,
  boxes,
  agencies,
  goods_delivery_statuses,
  pallets,
  pallet_boxes,
  assemble_orders,
  invoices,
  goods_delivery_boxes,
  outbound_pickers,
  delivery_partners,
  in_container_pickers,
  sfa_receipts,
  boxes_available_in_pallet,
  boxes_available_for_distribution,
  boxes_available_in_lading_bill,
  lading_bills_available_for_invoice,
  out_container_pickers,
  box_items,
  locations,
  boxes_available_for_goods_delivery,
  decouple_box,
  create_parent_box,
  pallets_of_sfa,
  place_of_deliveries,
  boxes_ref_decouple,
  boxes_freedom,
  boxes_parent,
  tracking_info,
  box_containers,
  boxes_management,
  merge_package,
  box_tags,
  boxes_not_distributed_yet,
  boxes_has_owners,
  boxes_without_container,
  pallets_list,
  change_sku_position,
  boxes_doesnt_have_owner,
  boxes_not_in_lading_bills,
  clear_empty_pallets,
  invoices_in,
  invoices_out,
  tracking_receipts,
  order_owning_boxes,
  decouple_box_items,
  boxes_decouple_items,
  statistics_quantity_products_inventory,
  freight_forwarders,
  container_types,
  house_airway_bills,
  statistics_quantity_in_inventory_user,
  cancel_distributed,
}
