import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'boxes',
    prefix_state: 'warehouse_boxes_not_distributed_yet',
    prefix: 'warehouse.boxes_not_distributed_yet',
  },
  {
    default_query: {
      sort: "-updated_at",
      include: "tags,items",
      "filter[doesnt_have_owners]": 1,
      "filter[is_parent_box]": 0,
    },
    detail_query: {
      include: "sfa"
    }
  },
).store()

export default resource
