import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'sfa_id', store: 'warehouse.sfas' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'owning_boxes_available',
    prefix_state: 'warehouse_boxes_available_for_goods_delivery',
    prefix: 'warehouse.boxes_available_for_goods_delivery',
  },
  {
    default_query: {
      type: "goods_delivery",
    }
  },
  touchs
).store()

export default resource
