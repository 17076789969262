import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'clear_empty_pallets',
  },
).store()

export default resource
