import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'change_sku_position',
  },
).store()


const PREFIX = "warehouse.change_sku_position";

const actions = {
  [PREFIX + ".change-position"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.warehouse.change_sku_position
        .changePosition(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};

